.form {
    background: rgb(25,181,64);
    background: linear-gradient(90deg, rgba(25,181,64,1) 0%, rgba(242,31,225,1) 100%);
}
.form input {
    background: none;
    color: white;
}
.form input:focus {
    background: none;
    color: white;
}
.form input::placeholder {
    color: white;
}

.note-details {
    font-size: 14px;
    color: #aaa;
}
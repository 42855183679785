.message-content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.message-content p {
    max-width: 255px;
    word-wrap: break-word;
    margin-bottom: 12px;
    line-height: 24px;
    position: relative;
    padding: 10px 20px;
    border-radius: 25px;
}
p:before, p:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 25px;
}
  
.send {
    color: white; 
    background: #0B93F6;
    align-self: flex-end;
}
.send:before {
    right: -7px;
    width: 20px;
    background-color: #0B93F6;
    border-bottom-left-radius: 16px 14px;
}
.send:after {
    right: -26px;
    width: 26px;
    background-color: white;
    border-bottom-left-radius: 10px;
}

.receive {
    background: #E5E5EA;
    color: black;
    align-self: flex-start;
}
.receive:before {
    left: -7px;
    width: 20px;
    background-color: #E5E5EA;
    border-bottom-right-radius: 16px;
}
.receive:after {
    left: -26px;
    width: 26px;
    background-color: white;
    border-bottom-right-radius: 10px;
}